/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

/* Reset some basic styles for consistency across browsers */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Define CSS Variables for Consistent Styling */
:root {
  --primary-color: #09453e;       /* Teal */
  --secondary-color: #264653;     /* Dark Slate */
  --accent-color: #e9c46a;        /* Mustard */
  --hover-color: #f4a261;         /* Light Orange */
  --border-color: #e0e0e0;        /* Light Gray */
  --text-color: #333;             /* Dark Gray */
  --background-color: #f9f9f9;    /* Very Light Gray */
  --badge-color: #e76f51;         /* Coral */
}

/* General Styles */
body {
  font-family: 'Montserrat', sans-serif;
}

.home {
  color: var(--text-color);
}

/* Hero Section */
.hero {
  background: linear-gradient(135deg, var(--primary-color) 0%, var(--secondary-color) 100%);
  color: #fff;
  padding: 60px 10px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.hero::after {
  content: '';
  background: url('https://source.unsplash.com/1600x900/?business,success') no-repeat center center/cover;
  opacity: 0.15;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 1;
}

.hero__content {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.hero__content h1 {
  font-size: 4rem;
  margin-bottom: 20px;
  line-height: 1.2;
  font-weight: 700;
  animation: fadeInDown 1s ease-out;
}

.hero__content p {
  font-size: 1.5rem;
  margin-bottom: 40px;
  animation: fadeInUp 1s ease-out;
}

.hero__cta {
  display: inline-block;
  padding: 15px 40px;
  background-color: var(--accent-color);
  color: #fff;
  border-radius: 50px;
  text-decoration: none;
  font-size: 1.2rem;
  transition: background-color 0.3s, transform 0.3s;
  cursor: pointer;
  font-weight: 600;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 1.2s ease-out;
}

.hero__cta:hover {
  background-color: var(--hover-color);
  transform: translateY(-5px);
}

/* Keyframes for animations */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design for Hero Section */
@media (max-width: 768px) {
  .hero__content h1 {
    font-size: 2.8rem;
  }

  .hero__content p {
    font-size: 1.2rem;
  }

  .hero__cta {
    padding: 12px 30px;
    font-size: 1rem;
  }

  .hero {
    padding: 80px 20px;
  }
}

/* Pricing Section */
.pricing-section {
  padding: 60px 20px;
  background-color: var(--background-color);
  text-align: center;
}

.pricing-section h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: var(--text-color);
}

.pricing-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  max-width: 1400px; /* Increased max-width to accommodate wider cards */
  margin: 0 auto;
}

/* Pricing Card */
.pricing-card {
  background: #fff;
  border: 2px solid var(--border-color);
  border-radius: 10px;
  padding: 30px 20px;
  width: 300px; /* Increased width for wider cards */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, border-color 0.3s, box-shadow 0.3s;
  position: relative; /* For positioning badges */
  cursor: pointer;    /* Indicates clickable cards */
}

.pricing-card:hover {
  transform: translateY(-5px);
  border-color: var(--primary-color);
  box-shadow: 0 6px 12px rgba(42, 157, 143, 0.2);
}

.pricing-card.current-plan {
  border-color: var(--primary-color);
  box-shadow: 0 6px 12px rgba(42, 157, 143, 0.2);
}

/* Badge for Current Plan */
.pricing-card.current-plan::before {
  content: 'Current Plan';
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: var(--badge-color);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
}

/* Pricing Card Heading */
.pricing-card h3 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: var(--primary-color);
}

/* Pricing Price */
.pricing-card .price {
  font-size: 2rem;
  color: var(--text-color);
  margin-bottom: 20px;
}

.pricing-card .price span {
  font-size: 1rem;
  color: #666;
}

/* Features List */
.pricing-card ul {
  list-style: none;
  padding: 0;
  margin-bottom: 25px;
  text-align: left;
}

.pricing-card ul li {
  margin-bottom: 10px;
  color: #555;
  position: relative;
  padding-left: 25px; /* Increased padding for better icon spacing */
}

.pricing-card ul li::before {
  content: '✔️';
  position: absolute;
  left: 0;
  color: var(--primary-color);
}

/* Optional: Add a button inside each pricing card */
.pricing-card button {
  padding: 10px 20px;
  background-color: var(--secondary-color);
  color: #fff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
  width: 100%;
}

.pricing-card button:hover {
  background-color: var(--primary-color);
}

.pricing-card.current-plan button {
  background-color: var(--primary-color);
}

.pricing-card.current-plan button:hover {
  background-color: #21867a;
}

/* Responsive Design */

/* For very large screens */
@media (min-width: 1600px) {
  .pricing-container {
    max-width: 1600px;
  }

  .pricing-card {
    width: 320px;
  }
}

/* For large screens */
@media (max-width: 1600px) {
  .pricing-container {
    max-width: 1400px;
  }

  .pricing-card {
    width: 300px;
  }
}

/* For medium screens */
@media (max-width: 1200px) {
  .pricing-container {
    max-width: 1000px;
  }

  .pricing-card {
    width: 45%; /* Two cards per row on medium screens */
  }
}

/* For small screens */
@media (max-width: 768px) {
  .pricing-card {
    width: 100%; /* Single column on small screens */
  }

  .hero__content h1 {
    font-size: 2.5rem;
  }

  .pricing-section h2 {
    font-size: 2rem;
  }

  .pricing-card h3 {
    font-size: 1.5rem;
  }

  .pricing-card .price {
    font-size: 1.5rem;
  }

  .hero {
    padding: 80px 20px;
  }

  /* Adjust CTA button size */
  .hero__cta {
    padding: 12px 25px;
    font-size: 0.9rem;
  }
}

/* Add this to your existing CSS file */

/* Styling for the "See our app on Shopify" button */
.hero__shopify-button {
  display: inline-block;
  margin-bottom: 20px;
  padding: 12px 30px;
  background-color: var(--accent-color);
  color: #000000;
  border-radius: 50px;
  text-decoration: none;
  font-size: 1.1rem;
  transition: background-color 0.3s, transform 0.3s;
  cursor: pointer;
  font-weight: 600;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 1.1s ease-out;
}

.hero__shopify-button:hover {
  background-color: var(--hover-color);
  transform: translateY(-5px);
}

/* Adjust margins for hero content */
.hero__content p {
  margin-top: 20px; /* Add some space between the new button and the paragraph */
}

/* Ensure the pricing cards maintain their styling when changed to <a> tags */
.pricing-card {
  /* Existing styles */
  display: block; /* Ensure the <a> tags behave like block elements */
  text-decoration: none; /* Remove underline from text */
  color: inherit; /* Inherit text color */
}
