/* src/pages/PrivacyPolicy.css */

.privacy-policy {
    max-width: 800px;
    margin: 50px auto;
    padding: 0 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.6;
    color: var(--text-color);
  }
  
  .privacy-policy h1 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 2.5rem;
    color: var(--primary-color);
  }
  
  .privacy-policy h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: var(--secondary-color);
    border-bottom: 2px solid var(--primary-color);
    padding-bottom: 5px;
  }
  
  .privacy-policy h3 {
    font-size: 1.6rem;
    margin-bottom: 15px;
    color: var(--primary-color);
  }
  
  .privacy-policy p {
    margin-bottom: 15px;
  }
  
  .privacy-policy ul {
    list-style: disc inside;
    margin-left: 20px;
    margin-bottom: 15px;
  }
  
  .privacy-policy ul li {
    margin-bottom: 10px;
  }
  
  .privacy-policy a {
    color: var(--primary-color);
    text-decoration: none;
  }
  
  .privacy-policy a:hover {
    text-decoration: underline;
  }
  
  .privacy-policy strong {
    color: var(--secondary-color);
  }
  
  /* Responsive Design */
  
  @media (max-width: 768px) {
    .privacy-policy {
      padding: 0 10px;
    }
  
    .privacy-policy h1 {
      font-size: 2rem;
    }
  
    .privacy-policy h2 {
      font-size: 1.5rem;
    }
  
    .privacy-policy h3 {
      font-size: 1.3rem;
    }
  }
  