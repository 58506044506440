/* src/pages/TermsOfService.css */

.terms-of-service {
    max-width: 800px;
    margin: 50px auto;
    padding: 0 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  .terms-of-service h1 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 2.5rem;
    color: var(--primary-color);
  }
  
  .terms-of-service section {
    margin-bottom: 30px;
  }
  
  .terms-of-service h2 {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: var(--secondary-color);
    border-bottom: 2px solid var(--primary-color);
    padding-bottom: 5px;
  }
  
  .terms-of-service p {
    margin-bottom: 15px;
  }
  
  .terms-of-service ul {
    list-style: disc inside;
    margin-left: 20px;
  }
  
  .terms-of-service ul li {
    margin-bottom: 10px;
  }
  
  .terms-of-service strong {
    color: var(--secondary-color);
  }
  