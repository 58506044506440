/* src/components/Header.css */

.header {
    background-color: #000000; /* Black background */
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
}

.header__container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 30px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative; /* To position the menu icon */
}

.header__brand-link {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.header__logo-image {
    height: 50px; /* Adjust as needed */
    width: auto;
    margin-right: 15px;
}

.header__company-name {
    color: #ffffff;
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: 1px;
    transition: color 0.3s ease;
}

.header__company-name:hover {
    color: #f0f0f0; /* Slight color change on hover */
}

.header__nav ul {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
}

.header__nav li {
    position: relative;
}

.header__nav li + li::before {
    content: "|";
    color: #ffffff;
    margin: 0 15px;
    font-size: 1rem;
}

.header__nav a {
    text-decoration: none;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 500;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    padding: 5px 0;
}

.header__nav a:hover {
    color: #ffcc00; /* Accent color on hover */
    border-bottom: 2px solid #ffcc00;
}

.header__nav .active a {
    color: #ffcc00; /* Accent color for active link */
    border-bottom: 2px solid #ffcc00;
}

/* Hamburger Menu Icon */
.header__menu-icon {
    display: none; /* Hidden on desktop */
    font-size: 1.5rem;
    color: #ffffff;
    cursor: pointer;
    z-index: 1100; /* Above the overlay */
}

/* Overlay */
.header__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    transition: opacity 0.3s ease;
}

/* Drawer Navigation */
.header__nav {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.header__nav ul {
    flex-direction: row;
}

@media (max-width: 768px) {
    .header__container {
        flex-direction: row;
        align-items: center;
        padding: 15px 20px;
    }

    /* Show Hamburger Menu on Mobile */
    .header__menu-icon {
        display: block;
    }

    /* Hide Navigation on Mobile by Default */
    .header__nav {
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        width: 250px;
        background-color: #000000;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 70px; /* To avoid overlapping the header */
        transform: translateX(100%); /* Hidden by default */
        box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5); /* Shadow when closed */
        z-index: 1050;
    }

    /* Show Navigation when Drawer is Open */
    .header__nav--open {
        transform: translateX(0);
        box-shadow: none; /* Remove shadow when open */
    }

    .header__nav ul {
        flex-direction: column;
        width: 100%;
    }

    .header__nav li + li::before {
        content: "";
        margin: 0;
    }

    .header__nav li {
        width: 100%;
        border-top: 1px solid #333333;
    }

    .header__nav li:first-child {
        border-top: none;
    }

    .header__nav a {
        display: block;
        width: 100%;
        padding: 15px 20px;
        border-left: 4px solid transparent;
        transition: background 0.3s ease, border-left 0.3s ease;
    }

    .header__nav a:hover {
        background-color: #111111;
        color: #ffcc00; /* Accent color on hover */
        border-left: 4px solid #ffcc00;
    }

    .header__nav .active a {
        background-color: #111111;
        color: #ffcc00; /* Accent color for active link */
        border-left: 4px solid #ffcc00;
    }

    /* Adjust Logo and Company Name */
    .header__logo-image {
        height: 45px;
        margin-right: 10px;
    }

    .header__company-name {
        font-size: 1.5rem;
    }
}
