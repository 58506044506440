/* src/components/Footer.css */

/* Footer Container */
.footer {
    background-color: #09453e; /* Original green background */
    color: #ffffff; /* White text for contrast */
    padding: 40px 20px; /* Adequate padding for spacing */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Consistent font */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Footer Text */
.footer p {
    margin-bottom: 12px; /* Space below the copyright */
    font-size: 1rem; /* Readable font size */
}

/* Footer Links Container */
.footer-links {
    display: flex;
    gap: 24px; /* Space between links */
}

/* Footer Links Styling */
.footer-links a {
    text-decoration: none; /* Remove underline */
    color: #ffffff; /* White text */
    font-size: 1rem; /* Consistent font size */
    font-weight: 500; /* Semi-bold for prominence */
    transition: color 0.3s ease, border-bottom 0.3s ease; /* Smooth transitions */
    padding: 5px 0; /* Vertical padding */
    border-bottom: 2px solid transparent; /* Transparent border for hover effect */
}

/* Footer Links Hover Effect */
.footer-links a:hover {
    color: #ffcc00; /* Accent color on hover */
    border-bottom: 2px solid #ffcc00; /* Underline on hover */
}

/* Responsive Design */

/* Tablets and Below */
@media (max-width: 1024px) {
    .footer-links {
        gap: 15px; /* Slightly smaller gap on medium screens */
    }
}

/* Mobile Devices */
@media (max-width: 768px) {
    .footer {
        padding: 30px 15px; /* Reduced padding for smaller screens */
    }
    
    .footer-links {
        flex-direction: column; /* Stack links vertically */
        align-items: center; /* Center align links */
        gap: 10px; /* Smaller gap between links */
    }
    
    .footer p {
        font-size: 0.9rem; /* Slightly smaller text on mobile */
        margin-bottom: 15px; /* Reduced margin */
    }
    
    .footer-links a {
        font-size: 0.95rem; /* Adjusted font size for mobile */
    }
}
